import React from "react"
import PropTypes from "prop-types"
// Components
import { graphql } from "gatsby"
import SEOTAG from '../components/seotag'
import Layout from '../components/Layout'
//import Img from "gatsby-image"
//import { Card, Row, Col } from 'react-bootstrap'
import { SlugLink } from '../components/utils'
//import Sidebar from '../components/Sidebar'
//import { LazyLoadImage } from 'react-lazy-load-image-component';
//import 'react-lazy-load-image-component/src/effects/blur.css';

const CategoryTemplate = ({ location, pageContext, data }) => {
  const siteTitle = data.site.siteMetadata.title
  //  const domainName = data.site.siteMetadata.domainName
  const shortName = data.site.siteMetadata.shortName
  // const posts = data.allMarkdownRemark.edges
  const { category } = pageContext
  const { edges } = data.allMarkdownRemark
  let fullTag = category;


  if (edges && edges.length > 0) {
    fullTag = edges[0].node.frontmatter.category;
  }
  const tagHeader = `${fullTag}`
  if (location != null) {
    if (location.href != null && location.href.endsWith('/')) {
      window.location.href = window.location.href.substring(0, window.location.href.length - 1);
    }
  }
  return (
    <Layout location={location} title={siteTitle}>
      <SEOTAG
        title={`${fullTag} - ${shortName}`}
        description={`${fullTag} - ${shortName}`}
      // keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />
      <div className="col-lg-8 p1030">
        <header className="arhl">
          <h1 className="arhl">{tagHeader}</h1>
        </header>
        {edges.map(({ node }) => {
          //  let featuredImgFluid = node.frontmatter.cover.childImageSharp.fluid.src
          const title = node.frontmatter.title || node.fields.slug
          return (
            <article className="row no-gutters post-id-single" key={node.fields.slug}>
              <div className="col-lg-12 col-md-12 post-id_blurb">
                <div className="d-flex justify-content-start post-id_card">
                  <div className="post-id_name">
                    <span className="post-id_published">
                      <time>{node.frontmatter.date}</time>
                    </span>
                    <span className="thecategory">
                      {node.frontmatter.category !== null ? <a href={`/category/${SlugLink(node.frontmatter.category)}`}>{node.frontmatter.category}</a> : 'Olymp Trade'}
                    </span>
                  </div>
                </div>
                <a className="post-id_link" href={node.fields.slug}>
                  <h2 className="post-id_headline-with-image">
                    {title}
                  </h2>
                </a>
                <div className="post-id_desc">
                  <div className="post-id_subhead" dangerouslySetInnerHTML={{ __html: node.frontmatter.description }} />
                </div>
                <div className="rmtc">
                  <a className="rmt" href={node.fields.slug}>
                    Read</a>
                </div>
              </div>
            </article>
          )
        })}
      </div>
    </Layout >
  )
}
CategoryTemplate.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export const pageQuery = graphql`
  query CategoryPage($category: String) {
    site {
      siteMetadata {
        title
        domainName
        shortName
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
      filter: { fields: { category: { eq: $category } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            category
          }
          excerpt(pruneLength: 160)
          timeToRead
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            tags
            category
            description
            cover {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
          }
        }
      }
    }
  }
`

export default CategoryTemplate